export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [];

export const dictionary = {
		"/": [7],
		"/[[community]]/community-error": [13,[4]],
		"/[[community]]/content-library": [14,[4]],
		"/[[community]]/custom-space/[uuid]": [15,[4]],
		"/[[community]]/doc/[uuid]": [16,[4]],
		"/[[community]]/edit/[...paths]": [17,[5]],
		"/error": [28],
		"/[[community]]/events": [18,[4]],
		"/[[community]]/events/[uuid]": [19,[4]],
		"/[[community]]/events/[uuid]/edit/[...paths]": [20,[4]],
		"/[[community]]/forum-space/[uuid]": [21,[4]],
		"/[[community]]/forum-space/[uuid]/[threadUuid]": [22,[4]],
		"/[[community]]/home": [23,[4]],
		"/[[community]]/join": [24,[6]],
		"/[[community]]/join/[inviteCode]": [25,[6]],
		"/(login)/login": [8,[2]],
		"/[[community]]/members": [26,[4]],
		"/(login)/sign-up": [9,[2]],
		"/(user)/user-home": [10,[3]],
		"/(user)/user-profile/[...paths]": [11,[3]],
		"/(user)/verify-email": [12,[3]],
		"/[[community]]/video-space/[uuid]": [27,[4]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';